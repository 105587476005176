import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
/**
 *
 * @param {*} data 导出的数组
 * @param {*} title 导出的文件名
 */
const Xlsx = (data, title) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };
  const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  try {
    saveAs(
      new Blob([excelData], { type: "application/octet-stream" }),
      title + ".xlsx"
    );
  } catch (error) {
    if (typeof console !== "undefined") {
      console.log(error, excelData);
    }
  }
};
const xlixs = (data, header, title) => {
  const ws = XLSX.utils.aoa_to_sheet([...[header], ...data]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "a");
  XLSX.writeFile(wb, title + ".xlsx");
};
export { Xlsx, xlixs };
